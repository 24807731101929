import userflow from 'userflow.js';

import { Administrator } from '@/admin/models/Administrator';
import { isProduction } from '@/common/utils/NextFunctions';

// Tokens from here https://app.userflow.com/app/komo-2/settings/environments
const token =
  process.env.NEXT_PUBLIC_KOMO_ENVIRONMENT === 'production'
    ? 'ct_f7irb7crpzhgtb2mjf2zg43jdm' // production
    : 'ct_g3djiomhvzckngrligad5nvdn4'; // staging

const init = () => {
  if (typeof window === 'undefined' || !isProduction()) return;

  userflow?.init(token);
};

const identifyAdmin = (admin: Administrator) => {
  if (typeof window === 'undefined' || !isProduction()) return;
  if (!admin?.id || !userflow) return;

  void userflow.identify(admin.id.toString(), {
    email: admin.email,
    name: admin.name,
    signed_up_at: admin.createdAt.toISOString()
  });
};

const forgetAdmin = () => {
  if (typeof window === 'undefined' || !isProduction()) return;

  userflow?.reset();
};

export const UserflowService = { init, identifyAdmin, forgetAdmin };
